import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Context } from '../../Context';
import { Link } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import './Inputs.css';

const translations = {
  укр: {
    alertMessage: 'ми отримали ваше повідомлення',
    title: 'досі залишились питання?',
    placeholders: {
      name: 'Ваше імʼя',
      nameError: 'імʼя обовʼязкове до заповнення',
      phone: 'номер телефону',
      phoneError: 'номер телефону обовʼязковий до заповнення',
      carBrand: 'марка вашого авто',
      city: 'місто',
    },
    agreementPrefix: '*Я прочитав і зрозумів',
    agreementLink: 'положення про конфіденційність',
    agreementSuffix: 'і погоджуюся отримувати інформаційний бюлетень та іншу маркетингову інформацію, як зазначено в ньому.',
    submitButton: 'відправити запит',
    link: '/privacy-policy',
  },
  eng: {
    alertMessage: 'we received your message',
    title: 'still have questions?',
    placeholders: {
      name: 'Your name',
      nameError: 'name is required',
      phone: 'phone number',
      phoneError: 'phone number is required',
      carBrand: 'your car brand',
      city: 'city',
    },
    agreementPrefix: '*I HAVE READ AND UNDERSTOOD',
    agreementLink: 'THE PRIVACY POLICY',
    agreementSuffix: 'AND AGREE TO RECEIVE THE NEWSLETTER AND OTHER MARKETING INFORMATION AS STATED IN IT.',
    submitButton: 'send request',
    link: '/en/privacy-policy',
  },
};

export default function Inputs() {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { language } = useContext(Context);
  const t = translations[language];

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    carBrand: '',
    city: '',
    agreement: false,
  });

  const [errors, setErrors] = useState({
    name: false,
    phone: false,
    agreement: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
    if (name === 'agreement' && checked) {
      setErrors({ ...errors, agreement: false });
    }
  };

  const validateForm = () => {
    const newErrors = {
      name: !formData.name,
      phone: !formData.phone,
      agreement: !formData.agreement,
    };
    setErrors(newErrors);
    return !newErrors.name && !newErrors.phone && !newErrors.agreement;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const url = 'https://messenger-8mp2.onrender.com/carCouture';
      const submissionData = {
        Імʼя: formData.name,
        Телефон: formData.phone,
        Авто: formData.carBrand,
        Місто: formData.city,
      };
      axios
        .post(url, submissionData)
        .then((response) => {
          setOpenAlert(true);
          setAlertMessage(t.alertMessage);

          setFormData({
            name: '',
            phone: '',
            carBrand: '',
            city: '',
            agreement: false,
          });
        })
        .catch((error) => {
          console.error('There was an error submitting the form!', error);
        });
    }
  };

  return (
    <section name="form" className="inputs">
      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert
          onClose={handleAlertClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%', backgroundColor: 'white', color: 'black', fontFamily: 'Garet', textTransform: 'uppercase' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <div className="inputs__title">{t.title}</div>

      <form className="inputs__form" onSubmit={handleSubmit}>
        <input
          className={`inputs__form-input ${errors.name ? 'error' : ''}`}
          placeholder={errors.name ? t.placeholders.nameError : t.placeholders.name}
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />

        <input
          className={`inputs__form-input ${errors.phone ? 'error' : ''}`}
          placeholder={errors.phone ? t.placeholders.phoneError : t.placeholders.phone}
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
        />

        <input className="inputs__form-input" placeholder={t.placeholders.carBrand} type="text" name="carBrand" value={formData.carBrand} onChange={handleChange} />

        <input className="inputs__form-input" placeholder={t.placeholders.city} type="text" name="city" value={formData.city} onChange={handleChange} />

        <label className={`inputs__form-label ${errors.agreement ? 'error' : ''}`}>
          <input type="checkbox" name="agreement" checked={formData.agreement} onChange={handleChange} />
          <span className="custom-checkbox"></span>
          <div className="inputs__form-label-wrapper">
            {t.agreementPrefix} {}
            <Link to={t.link} className="inputs__form-label-link">
              {t.agreementLink}
            </Link>
            {} {t.agreementSuffix}
          </div>
        </label>

        <button className="inputs__form-button" type="submit">
          {t.submitButton}
        </button>
      </form>
    </section>
  );
}
